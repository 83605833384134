export const faqVariants = [
  {
    question: 'frequently-asked-questions.first-question.question',
    answer: 'frequently-asked-questions.first-question.answer',
  },
  {
    question: 'frequently-asked-questions.second-question.question',
    answer: 'frequently-asked-questions.second-question.answer',
  },
  {
    question: 'frequently-asked-questions.third-question.question',
    answer: 'frequently-asked-questions.third-question.answer',
  },
  {
    question: 'frequently-asked-questions.fourth-question.question',
    answer: 'frequently-asked-questions.fourth-question.answer',
  },
  {
    question: 'frequently-asked-questions.fifth-question.question',
    answer: 'frequently-asked-questions.fifth-question.answer',
  },
  {
    question: 'frequently-asked-questions.sixth-question.question',
    answer: 'frequently-asked-questions.sixth-question.answer',
  },
]
