import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { useChangeLanguage } from 'remix-i18next/react'

import { useSimulateStats } from 'app/hooks/simulated-stats'
import {
  fetchBurnsStats,
  fetchHomepage,
  fetchStrapiLastMarketplaceBuyback,
  getGemsList,
} from '~/api/fetchers'
import { GemStatus } from '~/api/types'
import { ivendPayBanner } from '~/assets/images'
import { GemsWithHeader } from '~/components/gems-with-header'
import { i18next, Namespace } from '~/i18n'
import { detectLocale } from '~/i18n/detect-locale'
import {
  CurrentShowcaseSection,
  FrequentlyAskedQuestions,
  HeroHP,
  TensetForBusinessSection,
  TensetUtilitiesSection,
} from '~/sections/homepage'
import { HomeBurnsSection } from '~/sections/homepage/buybacks'
import { withCache } from '~/utils'

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = detectLocale(request)
  const t = await i18next.getFixedT(locale, Namespace.HOMEPAGE)

  const title = t('meta.title')
  const description = t('meta.description')

  const [
    gems,
    homepageApiData,
    burnsStatsApiData,
    strapiLastMarketplaceBuybackApiData,
  ] = await Promise.all([
    getGemsList(1, 100).then(gems => gems || []),
    fetchHomepage(),
    fetchBurnsStats(),
    fetchStrapiLastMarketplaceBuyback(),
  ])

  return withCache(
    json({
      locale,
      title,
      description,
      homepageApiData,
      burnsStatsApiData,
      strapiLastMarketplaceBuybackApiData,
      gems,
    })
  )
}

export const handle = {
  i18n: [Namespace.HOMEPAGE, Namespace.COMMON],
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data!.title },
    {
      name: 'description',
      content: data!.description,
    },
  ]
}

export default function Component() {
  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])
  const {
    locale,
    homepageApiData,
    burnsStatsApiData,
    strapiLastMarketplaceBuybackApiData,
    gems,
  } = useLoaderData<typeof loader>()

  const currentGems = gems.filter(gem => gem.status === GemStatus.CURRENT)
  const previousGems = gems
    .filter(gem => gem.name === 'fame-mma' || gem.name === 'metahero')
    .slice(0, 2)

  useChangeLanguage(locale)

  const simulatedStats = useSimulateStats({
    marketDataApiData: homepageApiData.marketdata,
    buybacksApiData: homepageApiData.buybacks,
    burnsStatsApiData: burnsStatsApiData,
    strapiLastMarketplaceBuybackApiData,
  })

  const isVisibleShowcaseSection = false

  return (
    <div className="container">
      <HeroHP />

      <HomeBurnsSection
        marketDataApiData={homepageApiData.marketdata}
        simulatedStats={simulatedStats}
      />

      <TensetUtilitiesSection />

      {currentGems.length > 0 && (
        <GemsWithHeader header={t('current-launches')} gems={currentGems} />
      )}

      {isVisibleShowcaseSection && (
        <CurrentShowcaseSection
          title={t('upcoming-launch')}
          imageSrc={ivendPayBanner}
          imageAlt="Banner with details about ivendapay presale"
          url="/news/first-tpl-launch-comig"
        />
      )}

      <GemsWithHeader header={t('previous-launches')} gems={previousGems} />

      <TensetForBusinessSection />
      <FrequentlyAskedQuestions />
    </div>
  )
}
