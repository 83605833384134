import { BuybacksExchangesSection } from './exchanges'
import { BuybacksHeroSection } from './hero'
import { BuybacksNftsSection } from './nfts'

import type { BurnsAndMarketDataProps } from '~/components/burns-and-market-data'
import { BurnsAndMarketData } from '~/components/burns-and-market-data'

type HomeBurnsSectionProps = BurnsAndMarketDataProps

export function HomeBurnsSection({
  marketDataApiData,
  simulatedStats,
}: HomeBurnsSectionProps) {
  return (
    <section>
      <BuybacksHeroSection />

      <div className="flex flex-col gap-8 md:gap-12">
        <BurnsAndMarketData
          marketDataApiData={marketDataApiData}
          simulatedStats={simulatedStats}
        />

        <BuybacksExchangesSection />

        <BuybacksNftsSection />
      </div>
    </section>
  )
}
