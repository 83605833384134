import { useTranslation } from 'react-i18next'

import { H2, Card, Icon } from '~/tenset-components'
import ReadMoreButton from '~/components/read-more-button'
import { Namespace } from '~/i18n'
import { cards } from '~/data/homepage/tenset-for-business'

export function TensetForBusinessSection() {
  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])

  return (
    <section className="flex flex-col gap-8 font-sora md:gap-12">
      <header>
        <H2 isBold>{t('tenset-for-business.title')}</H2>
      </header>

      <main className="grid grid-cols-1 gap-8 md:grid-cols-3 md:flex-row lg:gap-12">
        {cards.map(({ title, image, description, link }) => (
          <Card
            title={t(title)}
            key={title}
            image={<Icon className="h-full w-full" name={image} />}
            action={<ReadMoreButton link={link} />}
          >
            {t(description)}
          </Card>
        ))}
      </main>
    </section>
  )
}
