import { useTranslation } from 'react-i18next'

import { Namespace } from '~/i18n'
import useLocalizePathname from '~/i18n/use-localize-pathname'
import {
  Button,
  ButtonVariant,
  Card,
  Icon,
  IconName,
  TextL,
} from '~/tenset-components'

export function BuybacksHeroSection() {
  const localizePathname = useLocalizePathname()
  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])

  return (
    <div className="mb-8 flex flex-col items-center md:mb-12 md:text-center">
      <Card
        isLarge
        title={t('buybacks.hero.title')}
        action={
          <Button
            to={localizePathname('/burns')}
            variant={ButtonVariant.Secondary}
            className="w-full md:w-auto"
          >
            {t('buybacks.hero.button')}
            <Icon name={IconName.ChevronRight16} />
          </Button>
        }
      >
        <TextL>{t('buybacks.hero.description')}</TextL>
      </Card>
    </div>
  )
}
