import { IconName } from '~/tenset-components'

export const tensetForBusinessCards = [
  {
    title: 'tenset-for-business.smart-contract-audits.title',
    description: 'tenset-for-business.smart-contract-audits.description',
    link: '/smart-contract-audits',
  },
  {
    title: 'tenset-for-business.smart-contract-creation.title',
    description: 'tenset-for-business.smart-contract-creation.description',
    link: '/smart-contract-development',
  },
  {
    title: 'tenset-for-business.blockchain-solutions.title',
    description: 'tenset-for-business.blockchain-solutions.description',
    link: '/ui-ux-for-crypto',
  },
]

export const cards = [
  {
    title: 'tenset-for-business.smart-contract-audits.title',
    description: 'tenset-for-business.smart-contract-audits.description',
    link: '/smart-contract-audits',
    image: IconName.ScAudits,
  },
  {
    title: 'tenset-for-business.smart-contract-creation.title',
    description: 'tenset-for-business.smart-contract-creation.description',
    link: '/smart-contract-development',
    image: IconName.ScDevelopment,
  },
  {
    title: 'tenset-for-business.blockchain-solutions.title',
    description: 'tenset-for-business.blockchain-solutions.description',
    link: '/ui-ux-for-crypto',
    image: IconName.UiUx,
  },
]
