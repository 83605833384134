import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isDevelopment } from '~/config'
import { tensetAddress } from '~/data/common'
import { tokensAvailableButtons } from '~/data/homepage/tokens-available-buttons'
import { Namespace } from '~/i18n'
import type { IconName } from '~/tenset-components'
import {
  Button,
  ButtonVariant,
  ExplorerButton,
  ExplorerType,
  H3,
  Icon,
} from '~/tenset-components'
import { Utils, getNetworkExplorer, useWallet } from '~/tenset-web3'

const { Network } = Utils

interface ExchangeButton {
  icon: IconName
  label: string
  link: string
}

interface ExchangesProps {
  elements: ExchangeButton[]
  header?: string
}

export function BuybacksExchangesSection() {
  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])

  const { wallet } = useWallet()

  const tensetTokenNetwork = isDevelopment
    ? Network.SMART_CHAIN_TESTNET
    : Network.SMART_CHAIN

  const tensetNetworkExplorer = getNetworkExplorer(tensetTokenNetwork)

  const addToMetaMask = async () => {
    if (!wallet) return

    await wallet.addToken(
      tensetTokenNetwork,
      tensetAddress!,
      '10SET',
      '18',
      'https://s2.coinmarketcap.com/static/img/coins/64x64/9089.png'
    )
  }

  const [handleAddToMetamask, setHandleAddToMetamask] = useState<() => void>()

  useEffect(() => {
    if (!wallet?.isMetaMask || !tensetTokenNetwork)
      return setHandleAddToMetamask(undefined)

    setHandleAddToMetamask(() => addToMetaMask)
  }, [wallet?.isMetaMask, tensetTokenNetwork])

  return (
    <div className="flex flex-col gap-8 md:gap-12">
      <div className="flex flex-col gap-4">
        <H3 isBold>{t('buybacks.exchanges.header.sc')}</H3>

        <div>
          <ExplorerButton
            address={tensetAddress}
            explorerUrl={tensetNetworkExplorer}
            explorerType={ExplorerType.TOKEN}
            handleAddToMetamask={handleAddToMetamask}
            fullAddress
            tokenStandard="BEP-20"
          />
        </div>
      </div>

      <Exchanges
        elements={tokensAvailableButtons}
        header={t('buybacks.exchanges.header.tokens')}
      />
    </div>
  )
}

export function Exchanges({ elements, header }: ExchangesProps) {
  return (
    <div className="flex flex-col gap-4">
      {header && <H3 isBold>{header}</H3>}

      <div className="flex flex-col gap-4 sm:flex-row">
        {elements.map(({ icon, label, link }) => (
          <Button variant={ButtonVariant.Secondary} to={link} key={label}>
            <Icon name={icon} /> <span>{label}</span>
          </Button>
        ))}
      </div>
    </div>
  )
}
