import { useTranslation } from 'react-i18next'

import { tensetUtilitiesCards } from '~/data/homepage/'
import { Namespace } from '~/i18n'
import useLocalizePathname from '~/i18n/use-localize-pathname'
import {
  Button,
  ButtonVariant,
  CardsSection,
  Icon,
  IconName,
} from '~/tenset-components'

export function TensetUtilitiesSection() {
  const { Secondary } = ButtonVariant
  const { ChevronRight16 } = IconName

  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])

  const localizePathname = useLocalizePathname()

  const cards = tensetUtilitiesCards.map(
    ({ title, description, image, actionButtonLabel, link }) => ({
      title: t(title),
      image: <Icon className="h-full w-full" name={image} />,
      children: t(description),
      action: (
        <Button
          to={checkIfExternal(link) ? link : localizePathname(link)}
          variant={Secondary}
          className="w-full sm:w-auto"
        >
          {t(actionButtonLabel)}

          <Icon name={ChevronRight16} />
        </Button>
      ),
    })
  )

  return <CardsSection title={t('tenset-utilities.title')} cards={cards} />
}

const checkIfExternal = (link: string) => link.startsWith('http')
