import { useTranslation } from 'react-i18next'

import useLocalizePathname from '~/i18n/use-localize-pathname'
import {
  ExpansionPanel,
  Button,
  ButtonVariant,
  H2,
  H3,
  TextL,
  ExpansionPanelTrigger,
  ExpansionPanelContent,
  ExpansionPanelItem,
} from '~/tenset-components'
import { Namespace } from '~/i18n'
import { faqVariants } from '~/data/homepage'

export function FrequentlyAskedQuestions() {
  const { t } = useTranslation([Namespace.HOMEPAGE, Namespace.COMMON])
  const localizePathname = useLocalizePathname()

  return (
    <section>
      <H2 isBold>{t('common:frequently-asked-questions-header')}</H2>

      <div className="mt-8 md:mt-12">
        <ExpansionPanel type="multiple">
          {faqVariants.map(({ question, answer }) => (
            <ExpansionPanelItem value={question} key={question}>
              <ExpansionPanelTrigger>{t(question)}</ExpansionPanelTrigger>

              <ExpansionPanelContent>
                <TextL>{t(answer)}</TextL>
              </ExpansionPanelContent>
            </ExpansionPanelItem>
          ))}
        </ExpansionPanel>
      </div>

      <div className="mt-8 md:mt-12">
        <H3 isBold>{t('common:cant-find-answer')}</H3>
      </div>

      <Button
        className="mt-6 w-full font-sora text-sm font-semibold md:mt-8 md:w-auto"
        variant={ButtonVariant.Primary}
        to={localizePathname('/contact')}
      >
        {t('common:ask-us')}
      </Button>
    </section>
  )
}
