import { IconName } from '~/tenset-components'

export const tensetUtilitiesCards = [
  {
    title: 'tenset-utilities.2-percent-of-every-transaction.title',
    description: 'tenset-utilities.2-percent-of-every-transaction.description',
    actionButtonLabel: 'tenset-utilities.burns',
    link: '/burns',
    image: IconName.TransactionFee,
  },
  {
    title: 'tenset-utilities.tenset-runs-one-of-the-top.title',
    description: 'tenset-utilities.tenset-runs-one-of-the-top.description',
    actionButtonLabel: 'tenset-utilities.gem-launch-platform',
    link: '/gem-launch-platform',
    image: IconName.Tglp,
  },
  {
    title: 'tenset-utilities.stake-tenset-asset.title',
    description: 'tenset-utilities.stake-tenset-asset.description',
    actionButtonLabel: 'tenset-utilities.infinity-airdrops',
    link: 'https://www.infinity.tenset.io/',
    image: IconName.Airdrop,
  },
]
