import Showcase from '~/components/blocks/showcase'
import { HrefType } from '~/tenset-components'
import useLocalizePathname from '~/i18n/use-localize-pathname'

interface CurrentShowcaseSectionProps {
  title: string
  imageSrc: string
  imageAlt: string
  url: string
}

export function CurrentShowcaseSection({
  title,
  imageSrc,
  imageAlt,
  url,
}: CurrentShowcaseSectionProps) {
  const localizePathname = useLocalizePathname()

  return (
    <section>
      <Showcase
        title={title}
        image={
          <img
            src={imageSrc}
            alt={imageAlt}
            className="w-full rounded-[10px]"
          />
        }
        link={{
          type: HrefType.Internal,
          url: localizePathname(url),
        }}
      />
    </section>
  )
}
