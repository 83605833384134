import { useTranslation } from 'react-i18next'

import { NftCard } from '~/components/blocks/nft-card'
import { nftCards } from '~/data/gem-launch-platform/nft-cards'
import { Namespace } from '~/i18n'
import { H3 } from '~/tenset-components'

export function BuybacksNftsSection() {
  const { t } = useTranslation([Namespace.HOMEPAGE])

  return (
    <div>
      <H3 isBold>{t('buybacks.exchanges.header.nfts')}</H3>

      <div className="overflow-x-auto">
        <div className="mt-6 flex gap-4 pb-4 md:grid md:md:grid-cols-[repeat(auto-fit,210px)]">
          {nftCards.map(
            ({ title, image, linkOpenSea, linkOkx, linkBlur, utilities }) => (
              <NftCard
                title={title}
                image={image}
                linkOpenSea={linkOpenSea}
                linkOkx={linkOkx}
                linkBlur={linkBlur}
                utilities={utilities}
                key={title}
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}
